<template>
  <div id="filter-alert">
    <div class="row">
      <div class="col-12">
        <SectionTabs :tabs="tab_component"/>
      </div>
    </div>
  
    <div class="row align-items-center my-3">
      <div class="col-12 col-lg-9">
        <h3 class="section-head-title ps-0 mb-3 mb-lg-0">
          <font-awesome-icon icon="bell" class="color-secondary"/>
          Crea o revisa el estado de las alertas generadas en la plataforma.
        </h3>
      </div>
      <div class="col-12 col-lg-3 d-flex justify-content-end">
        <router-link 
          to="/alerta-push/add" 
          class="btn btn-custom btn-custom-color-blue btn-shadow border-round-50">
          <font-awesome-icon icon="plus" class="pe-2"/>
          Agregar alerta
        </router-link>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col-12 col-lg-6 mb-3">
        <div class="row">
          <div class="col-12 col-lg-6 mb-3 mb-lg-0 input-search">
            <input 
                type="text" 
                class="form-control input-custom"
                placeholder="Buscar notificación"
                v-model="buscar_texto"
                @keyup="filterText()"/>
                <span class="input-search-icon" @click="getPosts()">
                  <font-awesome-icon icon="search"/>
                </span>
          </div>
          <div class="col">
            <v-select 
              class="selvue-custom"
              :options="estados"
              label="nombre_estado"
              v-model="sel_estado"
              :clearable="false"
              placeholder="Filtrar por estado">
            </v-select>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row">
          <div class="col-12 col-lg-3 col-xl-4 d-flex align-items-center justify-content-lg-end text-secondary">
            <font-awesome-icon 
                icon="filter" 
                class="color-secondary pe-1"
              />
              Filtrar
          </div>
          <div class="col-12 col-lg-9 col-xl-8">
            <div class="row" v-click-outside="handleCalendar">
              <div class="col-6">
                <div 
                  class="form-control input-calendar" 
                  @click="openCalendar('start-date')">
                  <span>{{ dateFormatymd(init_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="init_date != 'Inicio'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div 
                  class="form-control input-calendar disabled" 
                  v-if="init_date == 'Inicio'">
                  <span>{{ end_date }}</span>
    
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                </div>
                <div
                  class="form-control input-calendar"
                  v-if="init_date != 'Inicio'"
                  @click="openCalendar('end-date')">
                  <span>{{ dateFormatymd(end_date) }}</span>
                  <div class="icon">
                    <font-awesome-icon icon="calendar"/>
                  </div>
                  <div
                    @click="clearDates()"
                    v-show="end_date != 'Término'"
                    class="input-calendar-clean">
                    <font-awesome-icon icon="times"/>
                  </div>
                </div>
              </div>
              <div class="col-12 position-relative">
                <div 
                  class="input-calendar-dropdown" 
                  :class="calendar_side" 
                  v-if="show_calendar">
                  <v-calendar
                    color="blue"
                    :max-date="max_date"
                    :min-date="min_date"
                    :attributes="attributes"
                    @dayclick="onDayClick"
                  />
                  <div 
                    class="border-arrow" 
                    :class="calendar_side">
                    <div class="arrow"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <Spinner v-if="show_spinner" />
        <ListTable v-if="!filtro" :alertas="alertPush_list" :paginated="paginas" :estados="estados"/>
        <ListTable v-else :alertas="alertas"/>
      </div>
    </div>
  </div>
</template>
<script>
import SectionTabs from "../Section/SectionTabs.vue";
import ListTable from "./ListTable.vue";
import { mapActions, mapState } from "vuex";
import Spinner from "../Spinner.vue";
export default {
  components: { SectionTabs, ListTable,Spinner },
  data() {
    return{
      tab_component: [
        {
          name: "Inicio",
          route: "push-list",
        },
        {
          name: "Agregar",
          route: "push-add",
        }
      ],
      // Filtros
      filtro: "",
      alertas: [],
      // Filtro texto
      buscar_texto:"",
      // Filtro Estado
      cat_selected: "Filtrar categoría",
      open_select: false,
      alerta_status: ["Todas","Enviada", "Programada", "Error", "Desactivada"],
      id_categoria: "0",
      // Filtro periodo
      img_calendar: require("@/assets/img/utilities/calendar.svg"),
      init_date: "Inicio",
      end_date: "Término",
      // Calendario
      calendar_side: "",
      show_calendar: false,
      min_date: "",
      max_date: "",
      start_calendar: false, //inicio
      date_start: "",
      end_calendar: false, //termino
      date_end: "",
      filtro_texto: false,
      filtro_estado: false,
      filtro_periodo: false,
      texto_alertas: [],
      estado_alertas: [],
      periodo_alertas_todas: [],
      periodo_alertas_activas: [],
      periodo_alertas_desactivas: [],
      show_spinner: false,
      selected_estado: "0",
      //FF
      // estados: [
      //   { id: 0, label: "Todas" },
      //   { id: 1, label: "Enviada" },
      //   { id: 2, label: "Programada" },
      //   { id: 3, label: "Error" },
      //   { id: 4, label: "Desactivada" }
      // ],
      sel_estado: "",
      pagina_default: "1"
    };
  },
  computed: {
    ...mapState("alertPushModule", ["alertPush_list", "estados","paginas"]),
    attributes() {
      return {
        highlight: true,
        color: "#009CDE",
        dates: new Date(),
      };
    },
  },
 async created(){
    this.getEstados();
    this.getNotificaicones();
 },
 async mounted() {
    // this.show_spinner = true;
    // // let alertPush_query = {
    // //   idUsuario: this.$ls.get("user").id_usuario,
    // //   textoNotificacion: "",
    // //   idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
    // //   fechaInicio: "",
    // //   fechaFin: "",
    // //   limit: "200",
    // //   lastId:  "0",
    // //   lastFecha: "",
    // // };
    // await this.getEstados();
    // //await this.getAlertPushStore(alertPush_query);
    // this.show_spinner = false;
  },
  methods: {
    ...mapActions("alertPushModule", ["getAlertPushStore","getEstadosAlertaPush", "getPaginasAlertas","getListaAlertaPush"]),
    // Obtiene los estados de las alerta push
    async getEstados(){
      let data_ = {}
      await this.getEstadosAlertaPush(data_);
    },

    async getNotificaicones(){
      this.show_spinner = true;
        let data_ = {
          id_usuario: this.$ls.get("user").id_usuario,
          texto_notificacion: this.buscar_texto,
          id_empresa: this.$ls.get("user").empresa[0].id_empresa,
          estado_notificacion:  this.selected_estado,
          fecha_inicio: this.init_date!="Inicio"?this.init_date:"",
          fecha_fin: this.end_date!="Término"?this.end_date:"",
          limite: "30"
        };
        await this.getPaginasAlertas(data_).then(res=>{
          if(res){
            res.forEach((data) => {
                  if (data.pagina == this.pagina_default) 
                  {
                    //this.ids_solicitudes = data.notificaciones;
                    let variables = {
                      id_empresa: this.$ls.get("user").empresa[0].id_empresa,
                      id_usuario: "0",
                      id_notificacion: data.notificaciones
                    }
                    this.getListaAlertaPush(variables);
                  }
                });
          }else{
            console.log("Falla carga de paginas en alerta push");
          }
        });
        this.show_spinner = false;
    },
    // Filtro text
    filterText(){
      this.getNotificaicones();
      // this.initializeFilter();
      // this.texto_alertas = [];
      // console.log("Texto a buscar: ", this.buscar_texto);
      // // busca por texto
      // if(this.buscar_texto != 0){
      //   this.texto_alertas = this.alertPush_list.filter( alerta => 
      //     alerta.texto_notificacion
      //       .toLowerCase()
      //       .includes(this.buscar_texto.toLowerCase())
      //   );
      //   this.filtro_texto = true;
      //   this.unifiedFilter();
      // }
      // else{
      //   this.texto_alertas = this.alertPush_list;
      //   this.filtro_texto= false;
      //   this.unifiedFilter();
      // }
    },
    // Filtro estado
    toggleSelect() {
      if (this.open_select) {
        this.open_select = false;
      } else {
        this.open_select = true;
      }
    },

    // filterStatus(status){
    //   this.initializeFilter();
    //   this.estado_alertas = [];
    //   if(status == "Enviada") this.id_categoria = "1";
    //   if(status == "Programada") this.id_categoria = "2";
    //   if(status == "Error") this.id_categoria = "3";
    //   if(status == "Todas") this.id_categoria = "0";
    //   if(status == "Activa") this.id_categoria = "4";
    //   if(status == "Desactivada") this.id_categoria = "5";
    //   this.cat_selected = this.id_categoria;

    //   // busca por estado
    //   if(status != "Todas"){
    //     this.alertPush_list.forEach( a => {
    //       if(this.verStatus(a, status) == true){
    //         this.estado_alertas.push(a); 
    //       } 
    //     });
    //     this.filtro_estado = true;
    //     this.unifiedFilter();
    //   }
    //   if(status == "Todas") {
    //     this.filtro_estado = false;
    //     this.estado_alertas = this.alertPush_list;
    //     this.unifiedFilter();
    //   }
    //   setTimeout(() => {
    //     this.open_select = false;
    //   }, 100);
    // },

    
    // status ver
    verStatus(alerta, status){
      // clasificar por activada y desactivada
      if(status == "Activa" || status == "Desactiva"){
        if(status == "Activa" && alerta.id_estado_fk == "1"){
          return true;
        }
        if(status == "Desactiva" && alerta.id_estado_fk == "3"){
          return true;
        }
      }
      if(status == "Enviada" || status == "Programada" || status == "Error"){
        if(this.nameState(alerta) == status){
          return true;
        }
      }
    },
    nameState(alerta) {
      if (alerta.fecha_notificacion != null) {
        let current_date = new Date();
        let shipping_date = new Date(alerta.fecha_notificacion);
        if (
          shipping_date > current_date &&
          alerta.push_enviado == "0" &&
          alerta.intentos == "0"
        ) {
          return "Programada";
        }
        if (shipping_date < current_date && alerta.push_enviado == "1") {
          return "Enviada";
        }
        if (shipping_date < current_date && alerta.push_enviado == "0") {
          return "Error";
        } else {
          return "Error";
        }
      }
      if (alerta.fecha_notificacion == null) {
        return "Programada";
      }
    },
    // Filtro periodo
    handleCalendar() {
      this.show_calendar = false;
    },
    deleteDate(){
      if(this.calendar_side == "end-date"){
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
        this.filterTime(this.date_start, this.date_end);
      } else {
        this.calendar_side = "",
        this.min_date = "";
        this.max_date = "";
        this.init_date = "Inicio";
        this.date_start = "";
        this.start_calendar = false,
        this.end_calendar = false;
        this.date_end = "";
        this.end_date = "Término";
        this.show_calendar = false; 
        // this.filtro = false;
        this.filtro_periodo= false;
        this.periodo_alertas= this.alertPush_list;
        //this.unifiedFilter();
      }
      //console.log("Borra fecha");
    },
    openCalendar(side){
      this.show_calendar = true;
      this.calendar_side = side;
      if(this.calendar_side == "start-date") {
        this.min_date = "";
        if(this.end_calendar == true){
          this.max_date = this.date_end;
        }
      }
      if(this.calendar_side == "end-date") {
        this.max_date="";
        if(this.start_calendar == true) {
          this.min_date = this.date_start;
        }
      }
    },
    onDayClick(day) {
      if(this.calendar_side == "start-date"){
        this.start_calendar = true;
        this.date_start = this.get_date_start(day.id);
        this.init_date = day.id;
      } 
      if(this.calendar_side == "end-date"){
        this.end_calendar = true;
        this.date_end = this.get_date_end(day.id);
        this.end_date = day.id;
      }
      // console.log("init: "+ this.date_start+", end: "+ this.date_end);
      this.filterTime(this.date_start, this.date_end);
      this.show_calendar = false;
    },
    get_date_start(day){
      let dia = day.toString() + 'T00:00:00';
      return dia;
    },
    get_date_end(day){
      let dia = day.toString() + 'T23:59:59';
      return dia;
    },
    filterTime(inicio, fin){
      console.log("inicio: ", inicio, " Termino: ", fin );
      if(fin!=""){
        this.getNotificaicones();
      }
    },

    clearDates(){
      this.end_date = 'Término';
      this.init_date = 'Inicio';
      this.date_start = '';
      this.date_end = '';
      this.inicio = '';
      this.fin = '';
      this.show_calendar = false;
      //console.log("clearDate");
      this.getNotificaicones();
    },

    initializeFilter(){
      this.filtro = true;
      if(this.filtro_texto == false && this.filtro_estado == false && this.filtro_periodo == false){
        this.alertas = this.alertPush_list;
        this.texto_alertas =this.alertPush_list;
        this.estado_alertas =this.alertPush_list;
        this.periodo_alertas = this.alertPush_list;
        this.periodo_alertas_activas = this.alertPush_list;
        this.periodo_alertas_desactivas= this.alertPush_list;
      } 
    },
    unifiedFilter(){
      // // agregar texto
      // let texInclude = this.alertPush_list.filter( a => this.texto_alertas.includes(a));
      // // agregar estado
      // let stateInclude = texInclude.filter ( a => this.estado_alertas.includes(a));
      // // agregar periodo 
      // let timeInclude = "";
      // console.log("cat_selected: ", this.cat_selected);
      // console.log("todas: " + this.periodo_alertas.length +
      // " , activadas: " + this.periodo_alertas_activas.length + 
      // " , desactivas: " + this.periodo_alertas_desactivas.length);
      // if(
      //   this.cat_selected == "5"
      // ) {
      //   timeInclude = stateInclude.filter(a => this.periodo_alertas_desactivas.includes(a));
      // } 
      // else if(
      //   this.cat_selected == "0" || 
      //   this.cat_selected== "Filtrar categoría"
      // ){
      //   timeInclude = stateInclude.filter(a => this.periodo_alertas.includes(a));
      // } 
      // else if
      // (
      //   this.cat_selected == "1" || this.cat_selected == "2" ||
      //   this.cat_selected == "3" || this.cat_selected == "4"
      // ){
      //   timeInclude = stateInclude.filter(a => this.periodo_alertas_activas.includes(a));
      // }
      // // resultado
      // // console.log("Llista de alertas: ", this.alertas);
      // this.alertas = timeInclude;
    },
  },
  watch: {
    sel_estado(value) {
      if(Object.keys(value).length === 0){
        this.selected_estado = "0";
      }
      else{
        this.selected_estado = value.estado_notificacion;
      }
      console.log(this.selected_estado);
      this.getNotificaicones();
      
    }
  }
};
</script>