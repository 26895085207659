<template>
  <div id="table-alert">
    <div class="row">
      <div class="col-12 mt-3">
        <div class="table-container">
          <table class="table table-custom table-borderless table-custom-responsive mb-0">
            <thead>
              <tr>
                <th scope="col">Mensaje</th>
                <th scope="col">Segmentación</th>
                <th scope="col">Estado</th>
                <th scope="col">Fecha de emisión</th>
                <th scope="col">Notificados</th>
                <th scope="col" class="text-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(alerta, k) in alertas"
                :key="k"
                :class="alerta.id_estado_fk == '1' ? '' : 'disabled'"
              >
                <td class="th th-nombre">
                  <font-awesome-icon
                    class="name-icon ban"
                    v-if="alerta.id_estado_fk != '1'"
                    :icon="['fas', 'ban']"/>
                  {{
                    alerta.texto_notificacion.length > 50
                    ? alerta.texto_notificacion.substring(0, 49) + "..."
                    : alerta.texto_notificacion
                  }}
                </td>
              <td class="th th-segmentacion">{{ alerta.titulo_notificacion }}</td>
                <td class="th th-estado text-start text-md-center">
                  <span 
                    v-if="state_name(alerta) == 'Enviado'"
                    class="badge badge-custom badge-custom-green-light">
                    <font-awesome-icon icon="check"/>
                    {{ state_name(alerta) }}
                  </span>
                  <span 
                    v-if="state_name(alerta) == 'Programado'"
                    class="badge badge-custom badge-custom-blue-light">
                    <font-awesome-icon icon="clock"/>
                    {{ state_name(alerta) }}
                  </span>
                  <span 
                    v-if="state_name(alerta) == 'Error'"
                    class="badge badge-custom badge-custom-red-light">
                    <font-awesome-icon icon="circle-exclamation"/>
                    {{ state_name(alerta) }}
                  </span>
                </td>
                
                <td class="th th-fecha-emision">{{ emision_date(alerta) }}</td>
                <td class="th th-notificados text-md-center" v-if="notified(alerta)">
                  <div class="progress">
                    <div 
                      :style="`width: ${ratio(alerta)}%`"
                      class="progress-bar"
                      :class="`${ratio(alerta)}` == '100' ? 'bg-success' : ''">
                      {{ ratio(alerta) }}%
                    </div>
                  </div>
                  <small class="text-muted">
                    <span class="badge border color-gray" v-if="`${ratio(alerta)}` < 20">
                      {{ ratio(alerta) }}%
                    </span>
                    {{ alerta.token_check }}/{{ alerta.token_totales }} usuarios
                  </small>
                </td>
                <td v-else>-</td>
                <td class="th th-accion text-center">
                    <div 
                      class="dropdown dropdown-custom d-flex justify-content-center" 
                      @click="openOption(alerta.id_notificacion)">
                      <button 
                        class="dropdown-toggle dropdown-custom-action dropdown-custom-arrow-hide" 
                        type="button" 
                        data-bs-toggle="dropdown">
                        <font-awesome-icon icon="ellipsis-vertical"/>
                      </button>
                      <ul 
                        class="dropdown-menu"
                        v-click-outside="outOption">
                        <li>
                          <a 
                            v-if="stateBoolProgramada(alerta)"
                            @click="statusSwitch(alerta)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon
                              class="pe-2"
                              :class="alerta.id_estado_fk  == '1' ? 'color-red' : 'color-secondary'"
                              :icon="
                                alerta.id_estado_fk == '1'
                                  ? ['fas', 'ban']
                                  : ['fas', 'check']"/>
                            {{ alerta.id_estado_fk  == "1" ? "Desactivar" : "Activar" }}
                          </a>
                        </li>
                        <li>
                          <a 
                            class="dropdown-item" 
                            @click="details(alerta)"
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-main" icon="eye"/>
                            Ver detalle
                          </a>
                        </li>
                        <li>
                          <a 
                            v-if="stateBoolProgramada(alerta)"
                            @click="openEditAlert(alerta.id_notificacion)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-brown" icon="pen"/>
                            Editar
                          </a>
                        </li>
                        <li>
                          <a 
                            v-if="stateBoolEliminar(alerta)"
                            @click="questionDelete(alerta)"
                            class="dropdown-item" 
                            href="javascript:">
                            <font-awesome-icon class="pe-2 color-red" icon="trash-alt"/>
                            Eliminar
                          </a>
                        </li>
                        <li>
                          <a
                            v-if="stateBoolError(alerta)"
                            @click="resend(alerta)"
                            class="dropdown-item"
                            href="javascript:">
                            <font-awesome-icon
                              class="pe-2 color-main"
                              icon="paper-plane"/>
                            Reenviar
                          </a>
                        </li>
                      </ul>
                    </div>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="modal-alerta" v-if="open_modal_details">
            <div class="modal-alerta__detalle">
              <div class="modal-alerta__up">
                <h2 class="modal-alerta__title">Vista previa</h2>
                <img
                  class="modal-alerta__close-modal"
                  src="@/assets/img/transporte/closeimg.svg"
                  alt=""
                  @click="open_modal_details = false"
                />
              </div>
              <hr class="modal-alerta__separacion-secciones" />
              <p class="modal-alerta__text">
                <span class="modal-alerta__text--negrita"> ID Notificación:</span>
                {{ detail.id }}
              </p>
              <p class="modal-alerta__text">
                <span class="modal-alerta__text--negrita"> Mensaje:</span>
                {{ detail.texto }}
              </p>
              <p class="modal-alerta__text">
                <span class="modal-alerta__text--negrita">Fecha de creación:</span>
                {{ date(detail.fecha_emision) }}
              </p>
              <p class="modal-alerta__text">
                <span class="modal-alerta__text--negrita"
                  >Fecha de emisión:</span
                >
                {{ date(detail.fecha_notificacion) }}
              </p>
              <p class="modal-alerta__text">
                <span class="modal-alerta__text--negrita">Estado:</span>
                {{ detail.estado }}
              </p>
              <hr class="modal-alerta__separacion-secciones" />
              <div class="modal-alerta__boton-container">
                <button
                  @click="open_modal_details = false"
                  class="modal-alerta__boton-cerrar"
                >
                  Cerrar
                </button>
                <button
                  v-show="detail.estado == 'Programado'"
                  @click="editDetails"
                  class="modal-alerta__boton-editar"
                >
                  Editar
                </button>
              </div>
            </div>
          </div>

          <div 
            v-if="alertas.length == 0"
            class="alert alert-info mb-0 mt-3">
            <font-awesome-icon class="pe-2" icon="circle-info"/>
              No tienes alertas creadas
          </div>
          
        </div>
        
        <div class="users-list__pagination" v-if="pagination.page_count > 0">
          <paginate
            v-model="pagination.actual_page"
            :page-count="paginated.length"
            :page-range="pagination.page_range"
            :click-handler="pagination.click_handler"
            :prev-text="pagination.prev_text"
            :next-text="pagination.next_text"
            :container-class="pagination.container_class"
            :page-class="pagination.page_class"
          />
        </div>
        <Question
          v-if="question_modal_enable"
          :msg="question_modal_msg"
          :hideCancel="false"
          @cancel="cancelEnable"
          @accept="acceptEnable"
        />
        <Question
          v-if="question_modal_delete"
          :msg="question_modal_msg"
          :hideCancel="false"
          @cancel="cancelDelete"
          @accept="acceptDelete"
        />
        <Status
          v-if="open_modal_status"
          :msg="modal_status_msg"
          :status="modal_status"
          @close="closeStatus"
        />
        <Spinner v-if="show_spinner" />

      </div>
    </div>
  </div>

</template>
<script>

import Spinner from "../Spinner.vue";
import Question from "../Modales/Question.vue";
import Status from "../Modales/Status.vue";
import { mapActions } from "vuex";

export default {
  components: { Question, Status, Spinner },
  props:{
    alertas: {
        type: Array,
    },
    paginated: {
      type: Array
    },
    estados:{
      type: Array
    }
  },
  data() {
    return {
      // Spinner
      show_spinner: false,
      // Question
      question_modal_enable: false,
      question_modal_delete: false,
      question_modal_msg: "",
      // Status
      open_modal_status: false,
      modal_status_msg: "",
      modal_status: true,
      // Details
      open_modal_details: false,
      open_modal_details_editar: false,
      // Acciones
      option: "",
      alerta_enable: null,
      detail: {
        id: "",
        texto: "",
        fecha_emision: "",
        fecha_notificacion: "",
        estado: "",
      },
      alerta_delete: null,
      pagina_actual: "1",
      pagination: {
        actual_page: 1,
        page_count: 1,
        page_range: 3,
        click_handler: this.paginacion,
        prev_text: '<div class="btn-prevnext"><i class="fas fa-chevron-left"></i></div>',
        next_text: '<div class="btn-prevnext"><i class="fas fa-chevron-right"></i></div>',
        container_class: "users-list__pagination-container",
        page_class:"pagination-item"
      },

    };
  },
  mounted() {
    //this.pagination.page_count = this.estados;
    console.log("data paginador: ",  this.paginated.length);
  },
  methods: {
    ...mapActions("alertPushModule", [
      "deleteAlertPushAction",
      "cambioEstadoAlerta",
      "reenviarAlerta",
      "getListaAlertaPush"
      ]),
    // Get alertas según pagina
    async getListaAlertaP(){
      this.show_spinner = true;
      this.paginated.forEach((data) => {
            if (data.pagina == this.pagina_actual) 
            {
              //console.log("Estoy para llamar a getListaAlertaPush")
              let variables = {
                id_empresa: this.$ls.get("user").empresa[0].id_empresa,
                id_usuario: "0",
                id_notificacion: data.notificaciones
              }
              this.getListaAlertaPush(variables);
            }
            else{
              console.log("data: ", data, "pagina_actual: ",this.pagina_actual);
            }
          });
      this.show_spinner = false;
    },
    // control de paginación
    paginacion(){
      console.log("estoy en la acción ");
      let total = this.paginated.length;
      if(this.pagination.actual_page <= total){
        //this.pagina_actual = total;
        console.log("para llamar al metodo de pagina");
        this.pagina_actual = this.pagination.actual_page;
        this.getListaAlertaP();
      }
      // else{
        
      //   this.getListaAlertaP();
      // }
    },
    // Estado
    state_name(alerta) {
      let found = this.estados.find(element => element.estado_notificacion == alerta.estado_notificacion);
      let data = "";
      //console.log("estados: ",found, "Alerta: ", alerta.texto_notificacion);
      if(Object.keys(found).length === 0){
        data = "Estado Error"
      }
      else{
        data = found.nombre_estado;
      }
      return data;
      // if (alerta.fecha_notificacion != null) {
      //   let current_date = new Date();
      //   let shipping_date = new Date(alerta.fecha_notificacion);
      //   if (shipping_date > current_date && alerta.push_enviado == "0" && alerta.intentos == "0") 
      //   {
      //     return "Programada";
      //   }
      //   if (shipping_date < current_date && alerta.push_enviado == "1") {
      //     return "Enviada";
      //   }
      //   if (shipping_date < current_date && alerta.push_enviado == "0") {
      //     return "Error";
      //   } else {
      //     return "Error"; 
      //   }
      // }
      // if (alerta.fecha_notificacion == null) {
      //   return "Programada";
      // }
    },
    // Fecha de emisión
    emision_date(alerta){
      if(this.state_name(alerta) == "Programado" || this.state_name(alerta) == "Error"){
        return this.date(alerta.fecha_notificacion);
      } else {
        return this.date(alerta.fecha_creacion);
      }
    },
    date(ver_fecha) {
      if(ver_fecha == null) return "Por definir"; 
      let fecha = new Date(ver_fecha);
      let año = fecha.getFullYear().toString();
      let mes = fecha.getMonth() + 1;
      mes = mes.toString();
      let dia = fecha.getDate();
      let horas = fecha.getHours().toString();
      let minutos = fecha.getMinutes().toString();
      let horario = "AM";
      if (parseInt(horas) > 11) {
        horario = "PM";
        horas = parseInt(horas) - 12;
        horas = horas.toString();
      }
      if (mes.length == 1) mes = "0" + mes;
      if (horas.length == 1) horas = "0" + horas;
      if (horas == "00") horas = 12;
      if (minutos.length == 1) minutos = "0" + minutos;
      return ( dia + "/" + mes + "/" + año + ", " + horas + ":" + minutos + " " + horario);
    },
    // Notificados
    notified(alerta){
      if(alerta.token_check != null && alerta.token_totales != null){
        return true;
      } else {
        return false;
      }
    },
    ratio(alerta) {
      let vistos = parseInt(alerta.token_check);
      let total = parseInt(alerta.token_totales);
      if (total == 0) { return "0" }
      let porciento = (vistos / total) * 100;
      porciento = porciento.toFixed(2);
      porciento = parseFloat(porciento);
      return porciento;
    },
    // Acciones
    openOption(id) {
      setTimeout(() => {
        this.option = id;
      }, 10);
    },
    outOption() {
      if (this.option != "") {
        this.option = "";
      }
    },
    // Habilitar/deshabilitar
    stateBoolProgramada(alerta) {
      if (this.state_name(alerta) == "Programado") {
        return true;
      } else {
        return false;
      }
    },
    stateBoolError(alerta) {
      if (this.state_name(alerta) == "Error") {
        return true;
      } else {
        return false;
      }
    },
    stateBoolEliminar(alerta) {
      if (
        this.state_name(alerta) == "Programado" ||
        this.state_name(alerta) == "Error"
      ) {
        return true;
      } else {
        return false;
      }
    },
    statusSwitch(alerta) {
      if (alerta.id_estado_fk == "1") {
        this.changeStatus("3", alerta.id_notificacion);
      } else {
        this.questionEnable(alerta);
        console.log("Cambiar estado a 1", alerta);
      }
    },

    async changeStatus(status, id) {
      this.show_spinner = true;
      let success_msg = status == "1" ? "activado" : "desactivado";
      //let err_msg = status == "1" ? "activar" : "desactivar";
      let data_ = {
        idNotificacion: id,
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idUsuario: this.$ls.get("user").id_usuario,
        idEstado: status,
      }
      this.cambioEstadoAlerta(data_)
      .then( res => {
        this.show_spinner = false;
        if (res!=null) {
          this.$toast.open({
                message: "Tú notificación ha cambiado de estado a "+success_msg,
                type: "success",
                duration: 6000,
                position: "top-right",
          });
        }else{
          this.$toast.open({
              message: "El proceso realizado ha fallado, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.show_spinner = false;
        this.$toast.open({
              message: "No se ha podido ejectuar el cambio de estado" +" favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
          });
      });
    },
    questionEnable(alerta) {
      this.alerta_enable = alerta;
      this.question_modal_msg =
        "Para habilitar una notificación debe volver a ingresar una fecha";
      this.question_modal_enable = true;
    },
    cancelEnable() {
      this.question_modal_enable = false;
    },
    acceptEnable() {
      this.$router.push({
        name: "push-edit",
        params: { id: this.alerta_enable.id_notificacion },
      });
    },
    // Vista previa
    details(alerta){
      this.detail = {
        id: alerta.id_notificacion,
        texto: alerta.texto_notificacion,
        fecha_emision: alerta.fecha_creacion,
        fecha_notificacion: alerta.fecha_notificacion,
        estado: this.state_name(alerta),
      };
      this.open_modal_details = true;
    },
    editDetails() {
      this.$router.push({
        name: "push-edit",
        params: { id: this.detail.id },
      });
    },
    // Editar
    openEditAlert(id_notificacion) {
      this.$router.push({
        name: "push-edit",
        params: { id: id_notificacion },
      });
    },
    // Eliminar
    questionDelete(alert) {
      this.alerta_delete = alert;
      this.question_modal_msg = "¿Estás seguro de eliminar esta alerta?";
      this.question_modal_delete = true;
    },
    cancelDelete() {
      this.question_modal_delete = false;
    },
    async acceptDelete() {
      this.show_spinner = true;
      let data_ = {
        idNotificacion: this.alerta_delete.id_notificacion,
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idUsuario: this.$ls.get("user").id_usuario,
      }

      await this.deleteAlertPushAction(data_)
      .then( res => {
        this.show_spinner = false;
        if (res!=null) {
          this.question_modal_delete = false;
          this.$toast.open({
                message: "Notificación eliminada con éxito",
                type: "success",
                duration: 6000,
                position: "top-right",
              });
        }
        else{
            this.question_modal_delete = false;
            this.$toast.open({
              message: "No se ha podido eliminar la notificación, intente nuevamente, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
          });
        }
      })
      .catch((err) => {
        console.log("err", err);
        this.show_spinner = false;
        this.$toast.open({
              message: "No se ha podido eliminar la notificación, intente nuevamente, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
          });
      });
    },
    // Reenviar
    async resend(alerta) {
      this.show_spinner = true;
      console.log("rev: ",alerta);
      let data_ = {
        idNotificacion: alerta.id_notificacion,
        idEmpresa: this.$ls.get("user").empresa[0].id_empresa,
        idUsuario: this.$ls.get("user").id_usuario,
      }
      
      await this.reenviarAlerta(data_)
      .then((res) => {
        this.show_spinner = false;
        if(res!=null){
          this.$toast.open({
            message: "Alerta reenviada con éxito",
            type: "success",
            duration: 6000,
            position: "top-right",
          });
        }
        else{
          this.$toast.open({
              message: "No se ha podido reenviar la notificación, intente nuevamente",
              type: "error",
              duration: 0,
              position: "top-right",
          });
        }
      })
        .catch((err) => {
          console.log("err", err);
          this.show_spinner = false;
          this.$toast.open({
              message: "No se ha podido reenviar la notificación, favor contactar a soporte@carpetres.cl",
              type: "error",
              duration: 0,
              position: "top-right",
          });
        });
    },
    // Modal Status
    closeStatus() {
      this.open_modal_status = false;
    },
    // Mensaje de lista vacia
    alertListVoid(){
      if(this.alertas.length == 0){
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>